import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/resouce_web/course',
    name: 'resourcesCourse',
    component: () => import ('../views/course.vue'),
  },
  {
    path: '/resouce_web/presentation/:id',
    name: 'resourcesPresentation',
    component: () => import ('../views/presentation.vue'),
  },
  {
    path: '/resouce_web/presentationEditor',
    name: 'presentationEditor',
    component: () => import ('../views/presentationEditor.vue'),
  },
]

const router = new VueRouter({
  routes
})

export default router
